<section id="privacyPolicy" class="privacy-policy-page">
  <h1>
    شروط و احكام استخدام منصة
    <span>تاجر</span>
  </h1>
  <p>
    توفر شركة تاجر مجموعة من الأدوات والموارد لإدارة المتاجر الإلكترونية، ومعالجة الطلبات وبيع
    المنتجات عبر الإنترنت وكذلك جميع الخدمات الإحترافية المتعلقة بذلك او الخدمات الأخرى ذات الصلة. و
    يشار الى جميع ما سبق بمصطلح "الخدمات".
  </p>
  <p>
    وفيما يلي البنود والشروط و الأحكام الخاصة باستخدام هذه الخدمات، إلى جانب أي تعديلات عليها وأي
    قواعد أو سياسات تشغيلية قد يتم نشرها من وقت لآخر بواسطة "تاجر".
  </p>
  <hr />
  <ul class="unstyled">
    <li>
      <p class="section-title">أولًا: الخدمات والدعم</p>
      <ol>
        <li>
          يتم تقديم الخدمات وفقًا لهذه الشروط وأي سياسات تشغيل قد تنشئها شركة تاجر؛ والتي يشار إليها
          جميعًا بمصطلح "الاتفاقية". قد تقوم شركة "تاجر" بإجراء تغييرات على هذه الاتفاقية وهذه
          الشروط، ويُعتبر الاستخدام المستمر لأي خدمات مُقدمة من الشركة بمثابة موافقة من العميل على
          أي من هذه التغييرات. بالإضافة إلى ذلك عند استخدام خدمات معينة فإن العميل وشركة تاجر يخضعان
          لأي إرشادات أو قواعد منشورة تنطبق على هذه الخدمات المعينة والتي يمكن نشرها من وقت لآخر.
        </li>
        <li>
          الخدمات متوفرة فقط للأشخاص المؤهلين الذين يمكنهم توقيع وإبرام عقود ملزمة قانونًا بموجب
          القانون المعمول به. دون تحديد ما تقدم، لا تتوفر الخدمات للأفراد الذين تقل أعمارهم عن 18
          عامًا. إذا لم تكن مؤهلاً، فيرجى عدم استخدام الخدمات.
        </li>
        <li>
          توفر الخدمات مجموعة من الأدوات والموارد لإدارة متجر إلكتروني ومعالجة الطلبات وكذلك بيع
          المنتجات عبر الإنترنت. بالإضافة إلى ذلك، قد تتضمن الخدمات المقدمة من قبل الشركة خدمات
          استشارية مهنية تتعلق باستخدامك لأدوات وموارد تاجر.
        </li>
        <li>
          يجب على العميل إكمال نموذج التسجيل حتى يمكنه الاستمتاع الكامل بالخدمات الخاصة بالشركة.
          سيقدم العميل معلومات صحيحة ودقيقة وحديثة وكاملة عن نفسه كما هو مطلوب في نموذج التسجيل،
          وسوف يقوم بتحديث المعلومات لإبقائها حديثة. كجزء من عملية التسجيل، سيحدد العميل عنوان
          البريد الإلكتروني وكلمة المرور لحساب عملاء تاجر. العميل مسؤول كذلك عن الحفاظ على أمان
          حسابه وكلمات المرور والملفات وعن جميع استخدامات حساب العميل والخدمات باسم العميل. كما
          تحتفظ شركة تاجر تاجر بالحق في رفض تسجيل أو إلغاء الحسابات التي تراها غير لائقة.
        </li>
      </ol>
    </li>
    <hr />
    <li>
      <p class="section-title">ثانيًا: القيود والمسؤوليات</p>
      <ol>
        <li>
          هذه اتفاقية للخدمات وبمقتضاها لم يتم منح العميل ترخيصًا لأي برنامج بموجب هذه الاتفاقية.
          وبناء عليها يتعهد العميل بأن لا يقوم بشكل مباشر أو غير مباشر بالتالي:
          <ul>
            <li>إجراء هندسة عكسية أو فك الشفرات او الأكواد الخاصة بالشركة.</li>
            <li>
              محاولة اكتشاف رمز او أكواد المصدر أو رمز او أكواد الكائنات البرمجية أو البنية الأساسية
              للخدمات أو الأفكار المستخدمة أو الخوارزميات أو الخدمات الأساسية أو المضمنة في الخدمات
              أو أي برامج او برمجيات أو وثائق أو بيانات متعلقة بـ الخدمات. و يشار الى هذا كله بمصطلح
              ("البرمجيات").
            </li>
            <li>
              تعديل أو ترجمة أو إنشاء أعمال مشتقة بناءً على الخدمات أو أي برنامج او برمجيات أو نسخ
              (باستثناء الأغراض الأرشيفية) أو توزيع أو تعهد أو تخصيص أو نقل حقوق أو تحويلها إلى
              خدمات أو أي برنامج.
            </li>
            <li>
              استخدام الخدمات أو أي برنامج لأغراض المشاركة الزمنية أو مكتب الخدمة أو غير ذلك لصالح
              طرف ثالث بدون موافقه كتابة من شركة تاجر.
            </li>
            <li>إزالة أي إشعارات أو علامات ملكية.</li>
          </ul>
        </li>
        <li>
          يقر العميل ويتعهد ويضمن أنه سوف يستخدم الخدمات فقط وفقًا لسياسات خصوصية شركة تاجر تاجر كما
          هو موضح أدناه أو مقدم بطريقة أخرى إلى العميل و يشار اليها بمصطلح "السياسة" وجميع القوانين
          المعمول بها (بما في ذلك على سبيل المثال لا الحصر السياسات والقوانين المتعلقة بـ البريد
          الإلكتروني العشوائي أو الخصوصية أو الفحش أو التشهير). لا يجوز للعميل الوصول إلى قوائم
          بريدية تابعة لجهات خارجية أو استخدامها بطريقة أخرى فيما يتعلق بإعداد أو توزيع بريد
          إلكتروني غير مرغوب فيه إلى أي طرف ثالث. يوافق العميل بموجبه على تعويض "تاجر" وتلافي الضرر
          المُلحق بها ضد أي أضرار أو خسائر أو مطلوبات أو تسويات أو نفقات (بما في ذلك على سبيل المثال
          لا الحصر تكاليف وأتعاب المحاماة المعقولة) فيما يتعلق بأي مطالبة أو إجراء ينشأ عن انتهاك
          مزعوم لما تقدم. على الرغم من أن تاجر ليست ملزمة بمراقبة المحتوى المقدم من قبل العميل أو
          استخدام العميل للخدمات، إلا أن تاجر قد تفعل ذلك وقد تزيل أي محتوى من هذا القبيل أو تقوم
          بحظر أي استخدام للخدمات التي يعتقد أنها (أو يُزعم أنها) مُنتهكة لما تقدم.
        </li>
        <li>
          لكل رسالة بريد إلكتروني يتم إرسالها فيما يتعلق بالخدمات، يقر العميل ويوافق على أن المستلم
          قد وافق على تلقي مثل هذه الاتصالات وأن العميل لن يشارك في عملية إرسال رسائل البريد
          الإلكتروني غير المرغوب فيها.
        </li>
        <li>
          عند استخدام الميزات المتنوعة للخدمات الخاصة بالشركة، يجوز للعميل تقديم معلومات (مثل الاسم
          أو معلومات الاتصال أو معلومات التسجيل الأخرى) إلى تاجر. إلى جانب ذلك قد تستخدم شركة تاجر
          هذه المعلومات وأي معلومات تقنية حول استخدام العميل لهذا الموقع لتخصيص عروضه التقديمية
          للعميل، أو تسهيل حركة العميل من خلال هذا الموقع، أو التواصل بشكل منفصل مع العميل. وتتعهد
          الشركة بأنها لن تقدم تلك المعلومات الخاصة بالعميل إلى الشركات التي لم يصرح لها العميل، ولن
          تسمح تاجر كذلك للشركات التي تحصل على هذه المعلومات ببيعها وإعادة توزيعها دون موافقة العميل
          المسبقة.
        </li>
      </ol>
    </li>
    <hr />
    <li>
      <p class="section-title">ثالثًا: فسخ التعاقد</p>
      <ol>
        <li>
          يجوز للعميل إنهاء هذه الاتفاقية في أي وقت عن طريق إرسال رسالة بريد إلكتروني إلى
          info@taager.com
        </li>
        <li>
          قد تقوم شركة تاجر بإنهاء هذه الاتفاقية أو الخدمات في أي وقت في حالة انتهاك أي من السياسات
          مع أو بدون إشعار. لا تتحمل الشركة أية مسؤولية تجاه العميل أو أي طرف ثالث بسبب هذا الإنهاء.
        </li>
        <li>
          يحق لـ "تاجر" إنهاء هذه الاتفاقية أو الخدمة في أي وقت إذا كان العميل قد استخدم الخدمة في
          البيع أو الترويج لأي منتجات أو خدمات غير قانونية. وبناء عليه، لا تتحمل شركة تاجر أية
          مسؤولية تجاه العميل أو أي طرف ثالث بسبب هذا الإنهاء.
        </li>
        <li>
          شركة "تاجر" لها كامل الحق في إنهاء هذه الاتفاقية أو الخدمة في أي وقت إذا استخدم العميل
          الخدمة في بيع أو ترويج المواد الإباحية أو أي مواد غير لائقة أو أي مواد قد تحرض على العنف
          أو التشهير بالأديان. وبناء عليه كذلك لا تتحمل الشركة أية مسؤولية تجاه العميل أو أي طرف
          ثالث بسبب هذا الإنهاء.
        </li>
        <li>
          عند الإنهاء لأي سبب من الأسباب ، يجوز لـ "تاجر" حذف أي بيانات مؤرشفة من قبل العميل في غضون
          90 يومًا من تاريخ الإنهاء. جميع أقسام هذه الاتفاقية التي يجب أن تظل بطبيعتها سارية المفعول
          حتى بعد انهاء الاتفاقية ستظل سارية المفعول بعد انهاء الاتفاقية و يشمل ذلك المسؤلية
          المحدودة او الغير محدودة.
        </li>
      </ol>
    </li>
    <hr />
    <li>
      <p class="section-title">رابعًا: شروط الاستخدام</p>
      <ol>
        <li>
          <p class="section-subtitle">الإلغاء والمبالغ المستردة.</p>
          <p>لا توفر منصة تاجر ضمان استعادة الأموال لتسجيلات الحساب الجديد تحت أي ظرف من الظروف.</p>
        </li>
        <li>
          <p class="section-subtitle">العروض المحظورة.</p>
          <p>
            لا يجوز لأي عميل استخدام الخدمات لتوفير أو بيع أو الترويج لبيع ما يلي: المواد الخاضعة
            للرقابة، الخمور، منتجات التبغ، المخدرات غير المشروعة وتهريب المخدرات، الأسلحة، مواد
            مقرصنة، تعليمات حول صنع أو تجميع أو الحصول على سلع أو أسلحة غير قانونية لمهاجمة الآخرين،
            أو انتهاك العلامة التجارية أو تدمير الملكية الفكرية أو المعلومات الخاصة بالآخرين،
            المعلومات المستخدمة في إيذاء أي شخص أو حيوانات بطريقة غير قانونية، المواد الإباحية أو
            العُري أو المنتجات أو البرامج أو الخدمات الجنسية، خدمات الحراسة أو غيرها من المحتويات
            التي تعتبر ذات صلة بالبالغين.
          </p>
        </li>
        <li>
          <p class="section-subtitle">الشتم</p>
          <p>الألفاظ النابية أو الموضوعات المهينة في محتوى الموقع وفي اسم المجال محظورة مطلقاً.</p>
        </li>
        <li>
          <p class="section-subtitle">معلومات خاصة وصور.</p>
          <p>
            لا يجوز للعملاء نشر أو الكشف عن أي معلومات شخصية أو خاصة أو صور أطفال أو أي طرف ثالث دون
            موافقة الطرف المذكور
          </p>
        </li>
        <li>
          <p class="section-subtitle">تحريف معلومات الإرسال</p>
          <p>
            يحظر تزوير أو تحريف أو حذف رؤوس الرسائل أو إعادة إرسال المعلومات البريدية أو عناوين
            بروتوكول الإنترنت لإخفاء أو تحديد أصل الرسالة.
          </p>
        </li>
        <li>
          <p class="section-subtitle">الفيروسات وغيرها من الأنشطة المدمرة</p>
          <p>
            يُحظر استخدام الخدمات لإنشاء أو إرسال فيروسات الإنترنت أو الفيروسات المتنقلة أو أحصنة
            طروادة أو فيضان أو تفجير عبر البريد أو المشاركة في هجمات رفض الخدمة. يحظر أيضًا على أي
            عميل المشاركة في أي نشاط آخر يهدف إلى تعطيل أو التدخل، أو ينتج عنه تعطيل أو تدخل في قدرة
            الآخرين على استخدام الخدمات بفعالية (أو أي شبكة أو نظام أو خدمة متصلة أو معدات) أو إجراء
            أعمالهم عبر الإنترنت.
          </p>
        </li>
        <li>
          <p class="section-subtitle">القرصنة</p>
          <p>
            "القرصنة" والأنشطة ذات الصلة محظورة مطلقاً. تشمل "القرصنة"، على سبيل المثال لا الحصر
            الأنشطة التالية: بشكل غير قانوني أو بدون إذن، الوصول إلى أجهزة الكمبيوتر أو الحسابات أو
            الشبكات، اختراق أو محاولة اختراق تدابير الأمان، عمليات فحص المنافذ، عمليات مسح التسلل،
            وغيرها من الأنشطة المصممة للمساعدة في القرصنة.
          </p>
        </li>
        <li>
          <p class="section-subtitle">وكلاء مجهولون "Anonymous Proxies".</p>
          <p>
            لاتسمح شركة "تاجر" باستخدام البرامج النصية الخاصة بالوكلاء المجهولين على خوادمها. يمكن
            أن تكون مسيئة للغاية لموارد الخادم، مما يؤثر على جميع المستخدمين على هذا الخادم.
          </p>
        </li>
        <li>
          <p class="section-subtitle">أنشطة غير قانونية أخرى.</p>
          <p>
            يحظر استخدام الخدمات للانخراط في أي نشاط تحدده شركة تاجر -وفقًا لتقديرها المطلق- بأنه
            غير قانوني. تتضمن هذه الأنشطة غير القانونية، على سبيل المثال لا الحصر، تخزين أو نشر أو
            إرسال أو بيع أو إتاحة مخططات بونزي أو المخططات الهرمية بطريقة أو بأخرى ، وشحن بطاقات
            الائتمان بشكل احتيالي أو عرض معلومات بطاقة الائتمان الخاصة بطرف ثالث دون موافقته، وعدم
            الامتثال لقوانين الخصوصية المطبقة على الإنترنت. تتعاون تاجر تعاونًا تامًا مع وكالات
            إنفاذ القانون المناسبة فيما يتعلق بأي وجميع الأنشطة غير القانونية التي تحدث في الخدمات
            أو من خلالها.
          </p>
        </li>
        <li>
          <p class="section-subtitle">تخزين النسخ الاحتياطي.</p>
          <p>
            تقدم شركة تاجر خدماتها لاستضافة مواقع التجارة الإلكترونية، وليس لتخزين البيانات. يحظر
            استخدام الحساب كمساحة تخزين عبر الإنترنت لأرشفة الملفات الإلكترونية، وسيؤدي ذلك إلى
            إنهاء الخدمات دون إشعار مسبق.
          </p>
        </li>
        <li>
          <p class="section-subtitle">نشاطات أخرى.</p>
          <p>
            الانخراط في أي نشاط يؤدي -حسب تقدير تاجر الوحيد والمطلق-إلى تعطيل أو التدخل أو ضرر (أو
            يهدد بتعطيل الخدمات أو أعمالها أو توسيعها أو إلحاق ضرر بها) أو علاقات العملاء، أو حتى
            قدرة عملاء تاجر على الاستخدام الفعال للخدمات محظورة. تشمل هذه الأنشطة المحظورة إتاحة أي
            برنامج أو منتج أو خدمة تم تصميمها أو يمكن استخدامها لانتهاك هذه الشروط. بالإضافة إلى
            ذلك، فإن إخفاق العميل في التعاون مع تاجر في تصحيح أو منع انتهاكات هذه الشروط من قبل، أو
            التي تنتج عن نشاط عميل أو راعٍ أو مشترك أو مدعو أو زائر أو ضيف يشكل انتهاكًا لـ هذه
            الشروط من قبل العملاء.
          </p>
        </li>
        <li>
          <p class="section-subtitle">النسخ الاحتياطية.</p>
          <p>
            من أجل كفاءاتها وأغراضها التشغيلية، تقوم تاجر من وقت لآخر بنسخ البيانات احتياطيًا على
            خوادمها، ولكنها ليست ملزمة أو ملزمة للعميل بالقيام بذلك بموجب هذه الشروط. إنه واجب
            العميل وحده ومسؤوليته عن النسخ الاحتياطي لملفات او بيانات العميل على انظمة تاجر. وتحت أي
            ظروف فإن تاجر لن تكون ملزمة لأي أحد بسبب أي أضرار من أي نوع او تحت أي نظرية قانونية تنتج
            عن فقد لملفات او بيانات العميل على أنظمة تاجر.
          </p>
        </li>
        <li>
          <p class="section-subtitle">القانون الحاكم.</p>
          <p>
            تخضع هذه الشروط لقوانين جمهورية مصر العربية دون الرجوع إلى مبادئ تنازع القوانين. لا تطبق
            اتفاقية الأمم المتحدة بشأن البيع الدولي للبضائع وتستبعد صراحةً. من خلال الاشتراك في أو
            استخدام أي من خدمات شركة التجارة الإلكترونية تاجر ، يوافق العميل على أن جميع المنازعات
            -إن وجدت- التي تنطوي على تاجر تخضع بشكل حصري للولاية القضائية لجمهورية مصر العربية؛
            شريطة، علاوة على ذلك، يجب رفع جميع الدعاوى المرفوعة ضد تاجر في المحكمة في جمهورية مصر
            العربية. يوافق العميل بموجب هذا على أنه يخضع للاختصاص الشخصي شخصيًا للمحاكم المذكورة
            لجميع الأغراض فيما يتعلق بهذه الشروط أو فيما يتعلق بأي مطالبة أو نزاع يتعلق بـ تاجر.
            يتنازل العميل بموجب هذه الوثيقة عن أي وجميع الاعتراضات التي لديه أو قد تكون، معروفة أو
            غير معروفة، سواء كانت خاضعة لجمهورية مصر العربية أو غير ذلك التي توجه بشكل شخصي. يوافق
            العميل على أنه لا يحق له رفع دعوى قضائية ضد تاجر خارج جمهورية مصر العربية ولا يجوز له
            رفعها. ويعتبر ذلك العميل، إذا تورط أمام محكمة في دعوى قضائية خارج جمهورية مصر العربية،
            داعماً ومؤيدًا وموافق على ان شركة تاجر رافضة للدعوى المذكورة فيما يتعلق بـ تاجر.
          </p>
        </li>
        <li>
          <p class="section-subtitle">تنويه</p>
          <p>
            أنت العميل، تقر بأن الخدمات والبرامج مقدمة "كما هي متوفرة" دون أي ضمان من أي نوع سوا
            الضمان المذكور على المنتجات المعروضة وفقا لشروط جهاز حماية المستهلك فقط لا غير. بموجبها
            تنصل شركة تاجر أي ضمان أو شرط فيما يتعلق بجودة الخدمة أو أدائها أو وظائفها أو برنامجها
            أو فيما يتعلق بجودة أو دقة أي معلومات تم الحصول عليها من الخدمة أو ان البرنامج خالي من
            الأخطاء أو خالٍ من الفيروسات أو مكونات ضارة أخرى. الخدمات والبرمجيات قد تحتوي على أخطاء.
            لا يوجد أي نصيحة أو معلومات يتم تقديمها بواسطة الشركة او افرادها او شركائها بما في ذلك،
            على سبيل المثال لا الحصر، مندوب دعم العملاء أو المستشارين المحترفين، قد تمثل أي ضمان من
            أي نوع. تتجاهل شركة تاجر جميع الضمانات أو الشروط، صريحة أو ضمنية أو قانونية، بما في ذلك
            التقييد أو الضمانات أو الشروط الخاصة بالشروط والامتيازات والامتثال الساري أو أي ضمانات
            أو شروط ضمنية. لا تضمن شركة تاجر أن المستخدمين سيكونون قادرين على استخدام الخدمات في
            أوقات أو مواقع اختيارهم. لا تضمن شركة تاجر أيضًا أن الخدمات تتوافق مع أي خدمة أو برنامج
            طرف ثالث، حتى إذا كانت هناك مطالبات من طرف ثالث أو ممثلين، فإن هذه الخدمة أو البرامج
            متوافقة مع أي خدمة من برامج شركة تاجر.
          </p>
        </li>
        <li>
          <p class="section-subtitle">القيود المفروضة على مسؤولية شركة تاجر.</p>
          <p>
            لن تكون مسؤولة عن الأداء أو التأخير الناتج عن أي سبب، سواء كان ذلك داخل أو خارج نطاق
            التحكم فيه. في أي حال من الأحوال، يجب أن تكون شركة تاجر مسؤولة عن العقد أو الإهمال أو
            الضرر أو المسئولية الصارمة أو أي نظرية قانونية أو قوانين أخرى لأي من الأضرار المباشرة أو
            غير المباشرة أو العرضية أو الاستثنائية أو التبعية من البيانات أو المعلومات من أي نوع أو
            خسارة من حسن نية العمل أو الفرصة) سواء تم أو لم يتم إخطار شركة تاجر تاجر من احتمال هذه
            الخسارة أو الأضرار. شركة تاجر لن تكون مسؤولاً عن تكلفة شراء السلع أو الخدمات أو
            التكنولوجيا. إن الشركة تتحمل المسؤولية الكاملة والانتصاف من العملاء فيما يتعلق بأي
            استخدام للخدمات هي إلغاء حساب العميل كما هو محدد هنا. في أي حال من الأحوال، لن تتحمل
            الشركة أي مسؤولية تجاهك، أكبر من دولار واحد (1.00 دولار) أو أي أموال يتم دفعها فعليًا
            نقدًا من جانبك –أي العميل- إلى شركة تاجر خلال الفترة التي تسبق شهر واحد. لا يجوز لك
            اتخاذ أي إجراء، بصرف النظر عن النموذج، والناشئ عن هذه الشروط أو خارج الخدمات، من قبلك،
            العميل، أكثر من سنة واحدة بعد الحدث الذي زاد من سبب الإجراء. لا تسمح بعض السلطات
            القضائية بتقييد المسؤولية عن الإهمال الذي يسبب الوفاة أو الإصابات الشخصية، وفي مثل هذه
            السلطات القضائية، يجب أن تكون مسؤولية الشركة مقصورة على أقصى مدى يسمح به القانون بموجب
            هذا القانون.
          </p>
        </li>
        <li>
          <p class="section-subtitle">التعويض في تاجر</p>
          <p>
            أنت، العميل، توافق على الدفاع، والتعويض، وحمل تاجر والشركات التابعة لها والجهات الراعية
            لها والشركاء وشركاء العلامة التجارية الآخرين والمديرين والمسؤولين والعاملين في كل ضرر من
            وضد أي وجميع المطالبات والخسائر والأضرار والمسؤوليات والتكاليف (بما في ذلك، على سبيل
            المثال لا الحصر، أتعاب المحاماة المعقولة وتكاليف المحكمة) الناشئة عن أو المتعلقة بخرقك
            لأي من هذه الشروط أو الاستخدام من جانبك أو بواسطة أي طرف ثالث من الخدمات، باستثناء ما
            ينتج عن ما تقدم مباشرة من تاجر الخاصة بالإهمال الجسيم أو سوء السلوك المتعمد. تحتفظ تاجر
            بالحق، على نفقتها الخاصة، في تولي الدفاع الحصري والتحكم في أي مسألة خاضعة بخلاف ذلك
            للتعويض من قبلك، العميل.
          </p>
        </li>
      </ol>
    </li>
  </ul>
</section>
